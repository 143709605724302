import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "month-paginator",
      "style": {
        "position": "relative"
      }
    }}>{`Month Paginator`}</h1>
    <p>{`Permite ao usuário navegar através de meses e anos.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Use o componente para controlar a navegação através de informações paginadas por mês.`}</li>
      <li parentName="ul">{`Se for necessário que o usuário selecione uma data específica, use o componente `}<a parentName="li" {...{
          "href": "/components/date-picker"
        }}>{`Date Picker`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}</h2>
    <Demo src='pages/components/month-paginator/FinancialMonthPaginator' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      